<template>
  <div class="ConstantBuy">
    <top-title>
      <template v-slot:name>常购清单</template>
    </top-title>

    
  </div>
</template>
<script>
import TopTitle from "./public/TopTitle";
export default {
  name: "ConstantBuy",
  data() {
    return {};
  },
  created() {},
  components: {
    TopTitle,
  },
};
</script>
<style lang='scss' scoped>

.ConstantBuy{
    background-color: white;
}

</style>