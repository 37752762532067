<template>
  <div class="title">
    <div class="top">
      <span><slot name="name"></slot></span>
      <slot name="rightBtn"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "TopTitle",
  data() {
    return {};
  },
  created() {},
};
</script>
<style lang='scss' scoped>

.title {
  border-bottom: 1px solid var(--border-color-default);
  margin-bottom: 0.75rem;
  .top {
    padding: 0.875rem 1rem;
    display: flex;
    justify-content: space-between;
    font-size: 0.875rem;
    color: var(--text-color-title);
  }
}
</style>