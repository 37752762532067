<template>
  <div class="attention">
    <top-title>
      <template v-slot:name>{{$t('User.Account.Pages.MyInterest')}}</template>
    </top-title>

    <div class="detil">
      <div class="goodNotice">
        <div class="num font">182</div>
        <div class="name font">商品关注</div>
      </div>
      <div class="shopNotice">
        <div class="num font">31</div>
        <div class="name font">店铺关注</div>
      </div>
    </div>
  </div>
</template>
<script>

import TopTitle from "./public/TopTitle";
export default {
  name: "Attention",
  data() {
    return {};
  },
  created() {},
  components: {
    TopTitle,
  },
};
</script>
<style lang='scss' scoped>

.attention {
  background-color: white;
    padding-bottom:1.5rem;
  .detil{
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 0.75rem;
      color:var(--text-color-default);

      .font{
          text-align: center;
      }

      .num{
          margin-bottom:0.75rem;
      }
  }
}
</style>