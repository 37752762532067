<template>
  <div class="MemberHome">
    <div class="topInfo">
      <member-account-info
        :memberData="memberData"
        class="accountInfo"
      ></member-account-info>
      <!-- 我的订单 -->
      <my-order :memberData="memberData" class="myOrder"></my-order>
    </div>
    <div class="detil">
      <!-- <div class="left"></div> -->
      <!-- <div class="right">
        <div class="oneMenu"> -->
          <!-- 我的关注 -->
          <attention class="attention"></attention>
        <!-- </div> -->
        <!-- <div class="twoMenu"> -->
          <!-- 常购清单 -->
          <div class="otherContent">
             <new-empty
              image="empty"
              decripe="暂无物流信息"
            ></new-empty>
          </div>
          <!-- <constant-buy class="constantBuy"></constant-buy> -->
        <!-- </div> -->
      <!-- </div> -->
    </div>
  </div>
</template>
<script>
import {
  MemberAccountInfo,
  MyOrder,
  Attention,
  ConstantBuy,
} from "./memberHomeChild";
import User from "@/model/User.js";
import NewEmpty from "@/components/common/empty/NewEmpty";
export default {
  name: "MemberHome",
  data() {
    return {
      //会员信息
      memberData: {},
    };
  },
  created() {
    this.getMemberData();
  },
  components: {
    MemberAccountInfo,
    MyOrder,
    Attention,
    ConstantBuy,
    NewEmpty
  },
  methods: {
    //请求会员信息数据
    getMemberData() {
      User.Info.Detail({
        data: {
          notredirect: "undefined",
        },
        succ: (res) => {
          this.memberData = res;
        },
        fail: (res, all) => {
          this.$message("error", all.msg);
        },
      });
    },
  },
  props: {},
};
</script>
<style lang='scss' scoped>
.MemberHome {
  margin-top: 0.875rem;
  .topInfo {
    display: flex;
    .accountInfo {
      width: 20%;
    }
    .myOrder {
      max-width: 80%;
      margin-left: 15px;
      flex-grow: 1;
    }
  }
  .detil {
    margin-top: 1.5rem;
    display: flex;
    .attention{
      width: 20%;
      height: 150px;
    }

    .otherContent{
       max-width: 80%;
      margin-left: 15px;
      flex-grow: 1;
      background-color: white;
      // height: 200px;

    }

    // .left {
    //   width: 100%;
    //   max-width: 70%;
    //   padding-right: 1rem;
    //   box-sizing: border-box;
    // }

    // .right {
    //   width: 100%;
    //   max-width: 30%;
    //   box-sizing: border-box;

    //   .oneMenu {
    //     margin-bottom: 1rem;
    //   }
    // }
  }
}
</style>