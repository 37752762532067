<template>
  <div class="myorder" >
    <top-title>
      <template v-slot:name>{{$t('User.Account.Pages.MyOrders')}}</template>
    </top-title>

    <div class="function">
      <div class="item">
        <my-icon
          :Iconconfig="Iconconfig"
          chooseIcon="icon-daifukuan"
          :isshowNum="memberData.userinfo && memberData.order_status_num.await_pay != 0"
          :num="memberData.userinfo && memberData.order_status_num.await_pay"
          @iconBtnClick="goOrderDel('await_pay')"
        ></my-icon>
        <p class="font">{{$t('User.Orders.Pages.PendingPayment')}}</p>
      </div>
      <div class="item">
        <my-icon
          :Iconconfig="Iconconfig"
          chooseIcon="icon-daishouhuo"
          :isshowNum="memberData.userinfo && memberData.order_status_num.shipped != 0"
          :num="memberData.userinfo && memberData.order_status_num.shipped"
             @iconBtnClick="goOrderDel('shipped')"
        ></my-icon>
        <p class="font">{{$t('User.Orders.Pages.Delivering')}}</p>
      </div>
      <div class="item">
        <my-icon
          :Iconconfig="Iconconfig"
          :isshowNum="true"
          :num="12"
          chooseIcon="icon-daipingjia01"
        ></my-icon>
        <p class="font">{{$t('User.Orders.Pages.PendingEvaluate')}}</p>
      </div>
      <div class="item">
        <my-icon
          :Iconconfig="Iconconfig"
          chooseIcon="icon-dunpaibaoxianrenzheng_o"
          :isshowNum="memberData.userinfo && memberData.aftersale_status_num.apply != 0"
          :num="memberData.userinfo && memberData.aftersale_status_num.apply"
        ></my-icon>
        <p class="font">{{$t('User.Orders.Pages.AfterSale')}}</p>
      </div>
      <div class="item">
        <my-icon
          :Iconconfig="Iconconfig_all"
          chooseIcon="icon-jiantouyou"
            @iconBtnClick="goOrderDel()"
        ></my-icon>
        <p class="font">{{$t('User.Orders.Pages.AllOrders')}}</p>
      </div>
    </div>

    <!-- <div class="expressage">
      <div class="item" v-for="item in 5" :key="item">
        <three padding="0.5rem 1rem" :allwidth="['55%', '30%', '15%']">
          <template v-slot:left>
            <div class="left">
              <div class="img">
                <img :src="require('assets/img/user/user.jpg')" alt="" />
              </div>
              <div class="font">
                <p class="state font_hide">[镇江市]快件人已取走邮件</p>
                <p class="property font_hide">
                  <span class="kd_class">普通快递</span>
                  <span class="line">|</span>
                  <span class="time">2021-10-20 14:32:23</span>
                </p>
              </div>
            </div>
          </template>
          <template v-slot:right>
            <div class="right">
              <span>查看详情</span>
            </div>
          </template>
        </three>
      </div>
    </div> -->
  </div>
</template>
<script>
import TopTitle from "./public/TopTitle";
import MyIcon from "common/icon/MyIcon";
import Three from "common/layout/Three";
export default {
  name: "MyOrder",
  data() {
    return {
      Iconconfig: {
        borderRadius: "0",
        width: "3rem",
        height: "3rem",
        lineHeight: "3rem",
        border: "none",
        fontSize: "2rem",
        color: "#71778e",
      },
      Iconconfig_all: {
        width: "3rem",
        height: "3rem",
        padding: "0.5rem",
        boxSizing: "border-box",
        lineHeight: "3rem",
        border: "none",
        fontSize: "1rem",
        color: "#71778e",
        backgroundColor: "#eeeeee",
        backgroundClip: "content-box",
      },
    };
  },
  created() {},
  components: {
    TopTitle,
    MyIcon,
    Three,
  },
  methods: {
    goOrderDel(target) {
      if(!target){
        return   this.$router.push({
        path: "/member/orders",
      });
      }
      this.$store.commit("updateOrderState", target);
      this.$router.push({
        path: "/member/orders",
        query:{
          status:target
        }
      });
    },
  },
  props: {
    memberData: {
      type: Object,
    },
  },
};
</script>
<style lang='scss' scoped>

.myorder {
  background-color: white;

  .function {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border-color-default);
    display: flex;
    justify-content: space-around;
    .item {
      width: 4rem;
      padding: 0.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: all 0.3s;
      cursor: pointer;
      &:hover {
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
        > .font {
          color: var(--red-color);
        }
      }
      > .font {
        text-align: center;
        font-size: 0.75rem;
        color: var(--text-color-default);
      }
    }
  }

  .expressage {
    .item {
      border-bottom: 1px solid var(--border-color-default);
      .left {
        display: flex;
        align-items: center;
        .img {
          flex-shrink: 0;
          width: 3.5rem;
          height: 3.5rem;
          img {
            width: 100%;
          }
        }

        .font {
          width: 100%;
          margin-left: 1rem;
          p {
            font-size: 0.75rem;
            line-height: 2;
            color: var(--text-color-default);
            .line {
              margin: 0 0.4rem;
            }
          }
        }
      }

      .right {
        padding-right: 0.75rem;
        text-align: right;
        span {
          font-size: 0.75rem;
          color: var(--text-color-default);
          cursor: pointer;
          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
  }
}
</style>
