<template>
  <div class="AccountInfo">
    <div class="left">
      <div class="user">
        <div class="profile">
          <div class="head">
            <img
              :src="
                config.config.SERVER_PRODUCTION.replace(
                  '/api',
                  memberData.userinfo && memberData.userinfo.avatar
                )
              "
              alt=""
            />
          </div>
          <div class="grade">
            {{ memberData.userinfo && memberData.userinfo.group_name }}
          </div>
        </div>
        <div class="name">
          <span>{{ memberData.userinfo && memberData.userinfo.nickname }}</span>
        </div>
        <!-- <div class="score">
            <div class="sco sco1">
              <span>京享值5964</span>
            </div>
            <div class="sco sco2">
              <span>小白守约701分</span>
            </div>
          </div> -->
        <!-- <div class="adv">
            <div class="advfont">
              <h3 class="title">开通PLUS会员尊享</h3>
              <p class="decripe">全年360元运费劵礼包</p>
            </div>
            <div class="btn">
              <span>立即续费</span>
            </div>
          </div> -->
      </div>
    </div>

    <div class="right" v-if="false">
      <top-title>
        <template v-slot:name>{{ $t("User.Account.Pages.MyWallet") }}</template>
        <template v-slot:rightBtn>
          <i
            class="iconfont icon-yanjing"
            v-show="isShowMon == true"
            style="font-size: 1.25rem"
            @click="isShowMon = !isShowMon"
          ></i>
          <i
            class="iconfont icon-biyan"
            v-show="isShowMon == false"
            style="font-size: 1.25rem"
            @click="isShowMon = !isShowMon"
          ></i>
        </template>
      </top-title>

      <div class="detil">
        <!-- <div class="coupon item">
          <h1 class="value">3</h1>
          <p class="name">{{ $t("Cart.Checkout.Pages.CouponCode") }}</p>
          <div class="bottom">
            <span class="godetilBtn" @click="openCoupon">{{
              $t("App.Selfpickup.Pages.ViewOrderDetail")
            }}</span>
          </div>
        </div> -->
        <!-- <div class="integral item">
          <h1 class="value">
            {{ memberData.userinfo && memberData.userinfo.point }}
          </h1>
          <p class="name">{{ $t("Cart.Checkout.Pages.Point") }}</p>
          <div class="bottom">
            <span class="godetilBtn" @click="openPoint">{{
              $t("App.Selfpickup.Pages.ViewOrderDetail")
            }}</span>
          </div>
        </div> -->
        <!-- <div class="balance item">
          <h1 class="value" v-if="isShowMon">
            {{ memberData.userinfo && memberData.userinfo.balance_text }}
          </h1>
          <h1 class="value" v-else>***</h1>
          <p class="name">{{ $t("Common.NavTitle.MyBalance") }}</p>
          <div class="bottom">
            <span class="godetilBtn" @click="openBalance">{{
              $t("App.Selfpickup.Pages.ViewOrderDetail")
            }}</span>
          </div>
        </div> -->
        <!-- <div class="tobepaid item">
            <h1 class="value">0.00</h1>
            <p class="name">白条本月待还</p>
            <div class="bottom">
              <span class="godetilBtn">{{$t('User.Orders.Button.Detail')}}</span>
            </div>
          </div>
          <div class="earnings item">
            <h1 class="value" v-if="isShowMon">0.00</h1>
            <h1 class="value" v-else>***</h1>
            <p class="name">小金库昨日收益</p>
            <div class="bottom">
              <span class="godetilBtn">{{$t('User.Account.Pages.ShiftTo')}}</span>
            </div>
          </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import TopTitle from "./public/TopTitle";
import config from "@/utils/config.js";
export default {
  name: "MemberAccountInfo",
  data() {
    return {
      config: config,

      // 是否隐藏或显示钱包
      isShowMon: true,
    };
  },
  created() {},
  components: {
    TopTitle,
  },
  props: {
    memberData: {
      type: Object,
    },
  },
  methods: {
    openCoupon() {
      this.$router.push({
        path: "/member/coupon",
      });
    },
    openPoint() {
      this.$router.push({
        path: "/member/point",
      });
    },
    openBalance() {
      this.$router.push({
        path: "/member/balance",
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.AccountInfo {
  .left {
    width: 100%;
    max-width: 100%;
    height: 100%;
    // min-height: 150px;
    box-sizing: border-box;
    position: relative;
    .user {
      position: absolute;
      width: auto;
      top: -0.5rem;
      bottom: -0.5rem;
      right: -0.5rem;
      left: 0;
      background-color: var(--red-color);
      box-shadow: 0.2rem 0.2rem 0.3rem rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .profile {
        position: relative;
        margin: 1.5rem 0 0.875rem 0;
        .head {
          width: 3rem;
          height: 3rem;
          overflow: hidden;
          border-radius: 50%;
          border: 2px solid white;
          box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
          img {
            width: 100%;
            transform: scaleX(1.2) scaleY(1.2);
          }
        }
        .grade {
          position: absolute;
          padding: 0.1rem;
          font-size: 0.75rem;
          bottom: -0.5rem;
          border-radius: 0.875rem;
          min-width: 4rem;
          text-align: center;
          background-color: white;
          left: 50%;
          transform: translateX(-50%);
          box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
        }
      }
      .name {
        margin-bottom: 0.875rem;
        margin-top: 0.5rem;
        display: flex;
        justify-content: center;
        span {
          color: white;
          font-size: 0.875rem;
          font-weight: 600;
        }
      }

      .score {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .sco {
          padding: 0.25rem;
          color: white;
          border-radius: 1.5rem;
          font-size: 0.75rem;
          border: 1px solid white;
        }

        .sco1 {
          margin-right: 0.5rem;
        }
      }

      .adv {
        margin-top: 0.875rem;
        width: 100%;
        box-sizing: border-box;
        padding: 0.875rem;
        background-color: #242331;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .advfont {
          .title,
          .decripe {
            color: #f3c94a;
            font-size: 0.75rem;
          }
        }
        .btn {
          padding: 0.4rem 0.5rem;
          background-color: #f3c94a;
          color: #242331;
          font-size: 0.75rem;
          border-radius: 1rem;
        }
      }
    }
  }

  .right {
    width: 100%;
    max-width: 75%;
    background-color: white;
    padding-bottom: 1.25rem;

    .detil {
      padding: 1rem 1rem;
      display: flex;
      // justify-content: space-between;
      .item {
        color: var(--text-color-title);
        width: 20%;
        box-sizing: border-box;
        .value {
          font-size: 1rem;
          margin-bottom: 0.875rem;
        }
        .name {
          font-size: 0.75rem;
          margin-bottom: 1.25rem;
        }

        .bottom {
          .godetilBtn {
            font-size: 0.75rem;
            color: var(--minor-color);
            cursor: pointer;
            &:hover {
              opacity: 0.9;
            }
          }
          .integralBtn {
            margin-right: 0.3rem;
            &::after {
              content: "|";
              margin-left: 0.3rem;
              font-size: 0.75rem;
              color: var(--text-color-default);
            }
          }
        }
      }
    }
  }
}
</style>